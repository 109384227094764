<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select
          placeholder="收入类型"
          v-model="query.type"
          @change="changeDate"
        >
          <el-option label="收入" :value="1"></el-option>
          <el-option label="支出" :value="2"></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-select
          placeholder="全部邀请人"
          v-model="query.userId"
          @change="getList(1)"
          clearable
        >
       
          <el-option
            v-for="(item, index) in userList"
            :label="item.realName"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6">
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          @change="changeDate"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="doExportIncomeList" v-auth="this.per.EXPORT_USER_PUSHER_LIST"
          >导出excel</el-button
        >
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="邀请人" prop="userId">
        <template #default="scope">
          <span>{{ userMap[scope.row.userId] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="被邀请人" prop="userName"></el-table-column>
      <el-table-column label="收益来源" prop="info"></el-table-column>

      <el-table-column label="金额（元）" prop="money">
        <template #default="scope">
          {{ scope.row.money / 100 }}
        </template>
      </el-table-column>

      <el-table-column label="时间" prop="createAt"> </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { fetchUserInviteRecord } from "@/api/user.js";
import { fetchBatteryManList, exportIncomeList } from "@/api/batteryMan.js";
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: mapState({
    agentList(state) {
      return state.agent.agentList || {};
    },
  }),
  data() {
    let startTime = moment().subtract(1, "month").format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().format("YYYY-MM-DD HH:mm:ss");

    return {
      dateRange: [startTime, endTime],
      dataSource: [],
      userList: [],
      userMap: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        isUse: -1,
        money: -1,
        inviteUid: -1,
        userId: -1,
        type: 1,
        way: -1,
        info: "",
        startTime:startTime,
        endTime:endTime,
      },
      modalData: {},
      total: 0,
    };
  },
  methods: {
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0]).format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1]).format("YYYY-MM-DD HH:mm:ss");
      }
    },

    // 导出数据
    doExportIncomeList() {
      exportIncomeList(this.query).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `${this.query.startTime}-${this.query.endTime}订单数据.xlsx`
        );
      });
    },
    getUserList() {
      fetchBatteryManList({
        query: {
          isPusher: 1,
          lastTime: "",
          unionid: "",
          lastIp: "",
          city: "",
          latitude: "",
          certificate: "",
          description: "",
          updateAt: "",
          createAt: "",
          score: -1,
          province: "",
          email: "",
          longitude: "",
          area: "",
          salt: "",
          address: "",
          level: -1,
          nickName: "",
          openid: "",
          sex: -1,
          userName: "",
          companyId: "",
          money: -1,
          phone: "",
          status: 1,
        },
        pageIndex: 1,
        pageSize: 100,
      }).then((res) => {
        this.userList = res.data.data.records;
        this.userList.map((item) => {
          this.userMap[item.id] = item.realName || item.nickName + "--未实名";
        });
      });
    },
    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }

      fetchUserInviteRecord({
        query: this.query,
        ...this.pages,
        iSortType: 2,
        sortField: "id",
      }).then((res) => {
        this.dataSource = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
  },

  mounted() {
    this.getList();
    this.getUserList();
  },
};
</script>
